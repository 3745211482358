<template>
  <div id="home">
    <div class="activity-detail">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/item-list' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'evaluate' }">综合评价</el-breadcrumb-item>
          <el-breadcrumb-item>2020-2021学年上学期综合评价报告</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <router-view></router-view>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
export default {
    data(){
        return {
            info:{},
            // defualtImage:'this.src="'+require("@/assets/images/course/activity-cover.png")+'"',
            text:'报名',
        }
    },
    methods:{
        async getActivityDetail(){
            let id = this.$route.params.id
            let resData = await this.$Api.Course.getActivityDetail(id);
        },
        signUp(){
            this.text = "已报名"
        }

    },
    mounted(){
    },
    activated(){
    }
}
</script>

<style lang="less">
/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1200px) {
  .breadcrumb {
    width: 1200px;
  }
  .activity-container {
    width: 1200px;
    margin: 0 auto;
  }
}
/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb {
    width: 950px;
  }
  .activity-container {
    width: 950px;
    margin: 0 auto;
  }
}

</style>